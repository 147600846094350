<template>
  <div v-html="terms">
  </div>
</template>
<script>
export default {
  name: "BMWTerms",
  props: {
      terms: String,
  },

};
</script>
